import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  Card,
  Label,
  Button,
  Title,
} from "@opsdti-global-component-library/amgen-design-system";
import axios from "axios";
import { BASE_ROUTE } from "../../../../utils/routing";
import { getApiUrl } from "../../../../api/api-config";
import { getOktaToken } from "../../../../utils/commonMethods";
import {
  apiErrorMessage,
  noDataMessage,
} from "../../../../assets/errorMessageConstants";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Loader } from "@gitlab-rtsensing/component-library";
import dayjs from "dayjs";
import { formatGraphDate } from "../../../../utils/commonMethods";
import { useLocation, useNavigate } from "react-router-dom";
  
  type AmgenStockData = {
    company_price: number;
    company_daily_change_percentage: number;
    company_daily_change_points: number;
    company_pe_ratio: string;
    company_mkt_cap: number;
    company_daily_range: {
        upper_value: number;
        lower_value: number;
        current_value: number;
    };
    company_yearly_range: {
      upper_value: number;
      lower_value: number;
      current_value: number;
    };
    company_logo_url: string;
  };

  type ChartData = {
    close: number;
    date: string;
  };

export const AmgenStockPerformance: React.FC = () => {
  const navigate = useNavigate();
  const currentRoute = useLocation();
  const [data, setData] = useState<AmgenStockData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState("");
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isChartError, setIsChartError] = useState("");
  const oktaToken = getOktaToken();

  const getStockData = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `${getApiUrl("get-companies")}?company_ticker=AMGN`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
        data: {},
      });
      if (res?.data?.status === "SUCCESS") {
        setData(res.data.data);
      } else {
        setIsError(noDataMessage);
      }
    } catch (error) {
      setIsError(apiErrorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const getStockChartData = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${getApiUrl("get-company-graph")}/AMGN/yearly`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res?.data?.status === "SUCCESS") {
        setChartData(res.data.data.graph_data);
      } else {
        setIsChartError(noDataMessage);
      }
    } catch (error) {
      setIsError(apiErrorMessage);
    } finally {
      setIsChartLoading(false);
    }
  };

  useEffect(() => {
    getStockData();
  }, []);

  useEffect(() => {
    getStockChartData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <div>{isError}</div>;
  }

  if (isChartLoading) {
    return <Loader />;
  }

  if (isChartError) {
    return <div>{isChartError}</div>;
  }

  const minClose = Math.min(...chartData.map(item => item.close));
  const maxClose = Math.max(...chartData.map(item => item.close));
  const buffer = (maxClose - minClose) * 0.1; 
  const yAxisDomain = [Math.floor(minClose - buffer), Math.ceil(maxClose + buffer)];

  const dataSet = chartData.map((item: any) => {
    return {
      formatted_date: formatGraphDate(item.date, "yearly").toUpperCase(),
      close: item.close,
    }
  }).reverse();

  const currentDate = dayjs().format("MMMM DD").toUpperCase();

  const currentTime = dayjs().tz("America/New_York").format("h:mm A z");

  const formatChangePoints = (points: number) => {
    if (points > 0) {
        return <span className="ext-amgen-stock-performance-positive-change">+{points}</span>;
    } else if (points < 0) {
        return <span className="ext-amgen-stock-performance-negative-change">{points}</span>;
    } else {
        return <span>{points.toFixed(2)}</span>;
    }
  };

  const formatPERatio = (peRatioString: string) => {
    const peRatioNumber = parseFloat(peRatioString.replace('x', ''));
    const formattedPERatio = peRatioNumber.toFixed(2);

    return formattedPERatio;
  }

  return (
    <div className="ext-amgen-stock-performance">
      <div className="ext-amgen-stock-performance-container">
        <Card
          title={{
            logo: data[0]?.company_logo_url ? (
              <img
                src={data[0].company_logo_url}
                alt="Company Logo"
                className="ext-amgen-stock-performance-logo"
              />
            ) : (
              <></>
            ),
            title: "AMGN Stock Performance",
          }}
        >
          <div className="ext-amgen-stock-performance-body-container">
            <div className="ext-amgen-stock-performance-stats-container">
              <Label colorVariant="secondary">
                {currentDate} {currentTime}
              </Label>
              <Title level={1}>
                {data[0]
                  ? `$${data[0].company_price.toFixed(2)}`
                  : "Data not available"}
              </Title>
              <Title level={3}>
                {data[0] ? (
                  <>
                    {formatChangePoints(data[0].company_daily_change_points)} (
                    {data[0].company_daily_change_percentage.toFixed(2)}%)
                  </>
                ) : (
                  "Data not available"
                )}
              </Title>
              <div className="ext-amgen-stock-performance-pe-ratio-container">
                <Label colorVariant="secondary">P/E RATIO</Label>
                <Title level={3}>
                  {data[0]
                    ? `${formatPERatio(data[0].company_pe_ratio)}`
                    : "Data not available"}
                </Title>
              </div>
            </div>
            <div className="ext-amgen-stock-performance-ranges-container">
              <div className="ext-amgen-stock-performance-daily-range-container">
                <Label colorVariant="secondary">TODAY'S RANGE</Label>
                <Title level={3}>
                  {data[0]?.company_daily_range
                    ? `$${data[0].company_daily_range.lower_value.toFixed(2)} - $${data[0].company_daily_range.upper_value.toFixed(2)}`
                    : "Data not available"}
                </Title>
              </div>
              <div className="ext-amgen-stock-performance-yearly-range-container">
                <Label colorVariant="secondary">52-WEEK RANGE</Label>
                <Title level={3}>
                  {data[0]?.company_yearly_range
                    ? `$${data[0].company_yearly_range.lower_value.toFixed(2)} - $${data[0].company_yearly_range.upper_value.toFixed(2)}`
                    : "Data not available"}
                </Title>
              </div>
              <div className="ext-amgen-stock-performance-market-cap-container">
                <Label colorVariant="secondary">MARKET CAP</Label>
                <Title level={3}>
                  {data[0]
                    ? `$${data[0].company_mkt_cap.toFixed(2)}B`
                    : "Data not available"}
                </Title>
              </div>
            </div>
            <div className="ext-amgen-stock-chart-container">
              <ResponsiveContainer width="100%" height={206}>
                <AreaChart data={dataSet}>
                  <Area
                    type="monotone"
                    dataKey="close"
                    fillOpacity={1}
                    fill="#E9F1F8"
                    stroke="#0063C3"
                  />
                  <defs>
                    <linearGradient id="#E9F1F8" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#000000" stopOpacity={0.1} />
                      <stop offset="95%" stopColor="#000000" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="formatted_date"
                    type="category"
                    axisLine={false}
                    minTickGap={40}
                    style={{
                      fontSize: "10px",
                      fill: "#9C9C9C",
                      fontWeight: "700",
                      lineHeight: "14px",
                    }}
                  />
                  <YAxis
                    dataKey="close"
                    domain={yAxisDomain}
                    axisLine={false}
                    tickLine={false}
                    minTickGap={60}
                    style={{
                      fontSize: "10px",
                      fill: "#9C9C9C",
                      fontWeight: "700",
                      lineHeight: "14px",
                    }}
                  />
                  <Tooltip />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="ext-row ext-page-navigation-wrapper">
            <Button
              text="VIEW PEER FINANCIALS & MARKET METRICS"
              type="secondary"
              style={{ maxWidth: "312px" }}
              onClick={() => {
                navigate(`${BASE_ROUTE}/companies`, {
                  state: {
                    callingPath: currentRoute.pathname,
                  },
                });
              }}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
