import React, { useEffect, useState, useMemo } from 'react';
import './index.scss';
import { Card } from '@opsdti-global-component-library/amgen-design-system';
import { Loader } from "@gitlab-rtsensing/component-library";
import { apiResponse } from '../../../../utils/commonMethods';
import {
    BarChart,
    Bar,
    YAxis,
    ResponsiveContainer,
    XAxis,
    Tooltip,
    Legend
} from "recharts";
import { apiErrorMessage, noDataMessage } from '../../../../assets/errorMessageConstants';

const POP_OVER_TEXT = "The bar chart analyzes articles ingested over the past 30 days across all publishers currently available in Sensing. It is updated daily to ensure timely insights."

export const PeerSentiment: React.FC = () => {
    interface Item {
        company: string;
        positive_count: number;
        negative_count: number;
    }
    const [sentimentList, setSentimentList] = useState<Item[]>([]);
    const [errorMesssage, setErrorMessage] = useState("");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    const getSentimentList = async () => {
    setIsDataLoading(true);
    try {
        const res = await apiResponse("get", "get-company-sentiment", [], {});
        if (res?.data) {
            const arrayData: Item[] = Object.values(res.data.companies_sentiments);
            setSentimentList(arrayData);
        } else {
            setErrorMessage(noDataMessage);
        }
        setIsDataLoading(false);
    } catch (error) {
        setIsDataLoading(false);
        console.log(error)
        setErrorMessage(apiErrorMessage);
    }
    };

      const calculatedData = useMemo (() => {
        return sentimentList.map((entry) => ({
            ...entry,
            sum: (entry.positive_count + entry.negative_count),
          }));
      }, [sentimentList]);
      

    useEffect(() => {
      getSentimentList();
    }, []);

    const CustomLegend = (props: any) => {
        const { payload } = props;
         
        return (
        <div>        
            <hr className='ext-divider-bar'/>          
            <ul style={{ display: 'flex', listStyleType: 'none', paddingLeft: 0 }}>       
                {payload.map((entry: any, index: number) => (         
                <li key={`item-${index}`} style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>            
                        
                <div style={{width: 8,   height: 8, borderRadius: '50%', backgroundColor: entry.color,  marginRight: 5, }} /> 
                <span style={{ color: '#000000' }}>{entry.value === 'Positive Articles' ? 'POSITIVE' : 'NEGATIVE'}</span></li> ))} 
            </ul>
        </div>
        );
    };

    return (
        <div className="ext-peer-sentiment">
            <div className="ext-peer-sentiment-container" style={{width:'100%'}}>
                <Card title={{title: 'Peer Sentiment: Perception by Articles', popoverContent: POP_OVER_TEXT, popoverIconColor: "secondary" }}>
                <div className="ext-article-list-wrapper ext-card-height ext-border-top-0" >
                    {isDataLoading && <Loader />}
                    {!isDataLoading && errorMesssage && (
                    <div className="ext-empty-chart-msg">{errorMesssage}</div>
                    )}
                    {sentimentList && sentimentList.length > 0 && (
                        <div className="ext-sentiment-content">
                            <ResponsiveContainer width='100%' height='100%' minHeight={512}>
                                <BarChart data={calculatedData} 
                                    layout='vertical'
                                    barSize={17}
                                >
                                <XAxis type='number' hide />
                                <YAxis
                                    width={90}
                                    dataKey="company" 
                                    type='category'
                                    tick = {{dx: -85, textAnchor: 'start', fontWeight: 'bold', color: '#141414'}}
                                    tickLine = {false} 
                                    axisLine = {false} 
                                />
                                <YAxis 
                                    yAxisId="right" 
                                    type="category"
                                    orientation="right"
                                    dataKey="sum"
                                    tickMargin={30}
                                    tick = {{textAnchor: 'end', fontWeight: 'bold', color: '#141414'}}
                                    tickFormatter={(value) => `${Math.floor(value)}`}
                                    tickLine = {false}
                                    axisLine = {false}
                                />
                                <Tooltip cursor={{fill: 'transparent'}} />
                                <Bar dataKey="negative_count" name="Negative Articles" stackId="a" fill="#FA541C" />
                                <Bar dataKey="positive_count" name="Positive Articles" stackId="a" fill="#0063C3" />
                                
                                <hr className='ext-divider-bar'/>
                                <Legend 
                                    verticalAlign='bottom'
                                    content={CustomLegend}
                                    />
                                </BarChart>
                                {/* <BarChart data={calculatedData} 
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: 20,
                                        bottom: 0
                                    }}
                                    layout='vertical'
                                    barSize={17}
                                    barCategoryGap ={17}>
                                <XAxis type='number' hide />
                                <YAxis dataKey="company" 
                                    type='category'
                                    tick = {{dx: -70, textAnchor: 'start', color: '#1F1F1F'}}
                                    tickLine = {false} 
                                    axisLine = {false} />
                                <YAxis yAxisId="right" 
                                    type="category"
                                    orientation="right"
                                    dataKey="sum"
                                    tickFormatter={(value) => `${Math.floor(value)}`}
                                    tickLine = {false}
                                    axisLine = {false}
                                    tick = {{
                                        fontWeight: 'bold',
                                        color: '#141414'
                                    }}/>
                                <Tooltip cursor={{fill: 'transparent'}} />
                                <Bar dataKey="negative_count" name="Negative Articles" stackId="a" fill="#FA541C" />
                                <Bar dataKey="positive_count" name="Positive Articles" stackId="a" fill="#0063C3" />
                                
                                <hr className='ext-divider-bar'/>
                                <Legend 
                                    verticalAlign='bottom'
                                    content={CustomLegend}
                                    />
                                </BarChart> */}
                            </ResponsiveContainer>
                        </div>
                    )}
                </div>
                </Card>
            </div>
        </div>
    )
}